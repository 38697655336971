import React, { Component } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import { TextField } from "@mui/material";
import { Navigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DiabetesConstants } from "./constants";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://genomsys.com/">
        GenomSys SA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function isMobile() {
  return (
    window.appInterface !== undefined ||
    (window.webkit && window.webkit.messageHandlers)
  );
}

function SubmitButton(props) {
  if (isMobile()) {
    // Call iOS interface
    return (
      <Button
        type="submit"
        fullWidth
        variant="contained"
        onClick={props.obj.handleSubmit}
        sx={{ mt: 3, mb: 2, backgroundColor: "#0b988a" }}
      >
        Submit
      </Button>
    );
  } else {
    return (
      <Grid
        container
        rowSpacing={0}
        columnSpacing={{ xs: 0, sm: 0, md: 0 }}
        sx={{ paddingTop: "20px", borderRadius: "20px" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            borderTop: 1,
            borderColor: "#d3d3d3",
            marginTop: "5px",
            color: "#fff",
            backgroundColor: props.obj.state.scoreBackground,
          }}
        >
          <div className="score-main">Your score</div>
          <div className="score">{parseInt(props.obj.state.score)}</div>
          <div id="score-sub"></div>
        </Grid>
      </Grid>
    );
  }
}

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.0rem",
    fontStyle: "italic",
  },
};

class DiabetesForm extends Component {
  state = {
    age: 0,
    gender: 0,
    relatives: 0,
    hypertension: 0,
    active: 0,
    height: 175,
    weight: 75,
    bmi: (75 / Math.pow(1.75, 2)).toFixed(2),
    bmiScore: 0,
    score: 0,
    scoreBackground: "#0b988a",
    message: "",
  };

  calcBmi() {
    const height = isNaN(this.state.height) ? 175 : this.state.height;
    const weight = isNaN(this.state.weight) ? 75 : this.state.weight;

    return parseFloat((weight / Math.pow(height / 100, 2)).toFixed(2));
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let msg =
      this.state.score > 4
        ? DiabetesConstants.riskMessage
        : DiabetesConstants.noRiskMessage;

    this.sendMessage(`score=${this.state.score},msg=${msg}`);
  };

  calcBmiScore() {
    if (this.state.bmi < 25) {
      return 0;
    } else if (this.state.bmi >= 25 && this.state.bmi < 30) {
      return 1;
    } else if (this.state.bmi >= 30 && this.state.bmi < 40) {
      return 2;
    } else if (this.state.bmi >= 40) {
      return 3;
    }
  }

  handleAgeChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.age }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ age: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleGenderChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.gender }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ gender: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleRelativesChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.relatives }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ relatives: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleHypertensionChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.hypertension }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ hypertension: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleActiveChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.active }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ active: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleActiveChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    this.setState({ score: this.state.score - this.state.active }, () => {
      this.setState({ score: this.state.score + newvalue }, () => {
        this.setState({ active: newvalue }, () => {
          this.updateScore();
        });
      });
    });
  };

  handleHeightChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    let oldBmi = this.calcBmiScore();
    this.setState({ height: newvalue }, () => {
      this.setState({ bmi: this.calcBmi() }, () => {
        this.setState(
          {
            score: this.state.score - oldBmi + this.calcBmiScore(),
          },
          () => {
            this.updateScore();
          }
        );
      });
    });
  };

  handleWeightChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    let oldBmi = this.calcBmiScore();

    this.setState({ weight: newvalue }, () => {
      this.setState({ bmi: this.calcBmi() }, () => {
        this.setState(
          {
            score: this.state.score - oldBmi + this.calcBmiScore(),
          },
          () => {
            this.updateScore();
          }
        );
      });
    });
  };

  updateScore() {
    if (!isMobile()) {
      this.setState(
        {
          scoreBackground: this.state.score < 5 ? "#0b988a" : "#f07d10",
        },
        () => {
          let element = document.getElementById("score-sub");

          if (this.state.score >= 5) {
            element.innerHTML = DiabetesConstants.riskMessage;
          } else {
            element.innerHTML = DiabetesConstants.noRiskMessage;
          }
        }
      );
    }
  }

  sendMessage(message) {
    if (window.appInterface !== undefined) {
      // Call Android interface
      window.appInterface.postMessage(message);
    } else if (window.webkit && window.webkit.messageHandlers) {
      // Call iOS interface
      window.webkit.messageHandlers.testMessage.postMessage(message);
    } else {
      // No Android or iOS interface found
      console.log("No native APIs found.");
    }
  }

  render() {
    if (window.localStorage.getItem("token") === null) {
      return <Navigate to="/login" />;
    }

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              American Diabetes Association (ADA) Risk Calculator
            </Typography>
            <Typography component="h3" variant="h3">
              Predicts risk of undiagnosed diabetes to determine who should be
              screened.
            </Typography>
            <Box noValidate sx={{ mt: 3 }}>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 0, sm: 0, md: 0 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Age, years</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    orientation="vertical"
                    value={this.state.age.toString()}
                    exclusive
                    onChange={this.handleAgeChange}
                  >
                    <ToggleButton value="0" size="small">
                      Less than 40
                    </ToggleButton>
                    <ToggleButton value="1" size="small">
                      40 to 49
                    </ToggleButton>
                    <ToggleButton value="2" size="small">
                      50 to 59
                    </ToggleButton>
                    <ToggleButton value="3" size="small">
                      60+
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Gender</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    value={this.state.gender.toString()}
                    exclusive
                    onChange={this.handleGenderChange}
                  >
                    <ToggleButton value="0" size="small">
                      Female
                    </ToggleButton>
                    <ToggleButton value="1" size="small">
                      Male
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">
                    1st degree relative with diabetes
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    value={this.state.relatives.toString()}
                    exclusive
                    onChange={this.handleRelativesChange}
                  >
                    <ToggleButton value="0" size="small">
                      No
                    </ToggleButton>
                    <ToggleButton value="1" size="small">
                      Yes
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Hypertension</div>
                  <div className="subbody-app">
                    Self-reported history of hypertension, prescribed
                    antihypertensive medication, and/or BP ≥140/90
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    value={this.state.hypertension.toString()}
                    exclusive
                    onChange={this.handleHypertensionChange}
                  >
                    <ToggleButton value="0" size="small">
                      No
                    </ToggleButton>
                    <ToggleButton value="1" size="small">
                      Yes
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Physically active</div>
                  <div className="subbody-app">Self-reported by patient</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    value={this.state.active.toString()}
                    exclusive
                    onChange={this.handleActiveChange}
                  >
                    <ToggleButton value="1" size="small">
                      No
                    </ToggleButton>
                    <ToggleButton value="0" size="small">
                      Yes
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <TextField
                    type="number"
                    required
                    fullWidth
                    value={this.state.height}
                    id="height"
                    label="Height (cm)"
                    name="height"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                      max: 239,
                    }}
                    onChange={this.handleHeightChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={3}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <TextField
                    type="number"
                    required
                    fullWidth
                    value={this.state.weight}
                    id="weight"
                    label="weight (kg)"
                    name="weight"
                    size="small"
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[0-9]*",
                      min: 0,
                      max: 199,
                    }}
                    onChange={this.handleWeightChange}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <p style={{ paddingTop: "8px" }}>
                    Body Mass Index {this.state.bmi}
                  </p>
                </Grid>
              </Grid>
              <SubmitButton obj={this}></SubmitButton>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    );
  }
}

export default DiabetesForm;
