import React from "react";
import { Route, Navigate, Routes } from "react-router-dom";

import GailForm from "./components/gailform";
import DiabetesForm from "./components/diabetesform";
import Login from "./components/login";

import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  return (
    <React.Fragment>
      <div className="App">
        <main className="container-fluid">
          <Routes>
            <Route exact path="/gail-form" element={<GailForm />} />
            <Route exact path="/diabetes" element={<DiabetesForm />} />
            <Route exact path="/login" element={<Login />} />

            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </main>
      </div>
    </React.Fragment>
  );
}

export default App;
