import React, { Component } from "react";

import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";

import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Select, MenuItem, InputLabel } from "@mui/material";
import { Navigate } from "react-router-dom";

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  useQuery,
  gql,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BcraConstants } from "./constants";
import { isAuthenticated } from "./utils";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://genomsys.com/">
        GenomSys SA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const testResponse = {
  data: {
    brCaRiskEstimation: {
      projectedAgeRisk: {
        absoluteRisk: 0.0049761436485379704,
        averagePopulationRisk: 0.005787445847717419,
      },
      lifetimeRisk: {
        absoluteRisk: 0.09033124915466921,
        averagePopulationRisk: 0.12368167626319901,
      },
    },
  },
};

const theme = createTheme();

theme.typography.h3 = {
  fontSize: "1.0rem",
  "@media (min-width:600px)": {
    fontSize: "1.0rem",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "1.0rem",
    fontStyle: "italic",
  },
};

class GailForm extends Component {
  state = {
    age: 35,
    ageMen: 14,
    race: 0,
    livebirth: -1,
    relatives: 0,
    biopsy: 0,
    hyperplasia: -1,
    resultVisibility: "hidden",
    circularVisibility: "hidden",
    lifetimeRisk: "",
    projectedRisk: "",
    lifetimePopRisk: "",
    projectedPopRisk: "",
    buttonDisabled: true,
    livebirthOptions: Array.from(Array(22), (e, i) => i + 14),
    ageOptions: Array.from(Array(71), (e, i) => i + 14),
    ageMenOptions: Array.from(Array(32), (e, i) => i + 7),
    client: {},
    dep_id: "dev",
  };

  componentDidMount() {
    const sp = new URLSearchParams(window.location.search);
    if (sp.has("dep_id")) {
      this.setState({ dep_id: sp.get("dep_id") });
    }

    if (sp.has("token")) {
      window.localStorage.setItem("token", sp.get("token"));
    }

    this.setState({
      ageMenOptions: Array.from(Array(32), (e, i) => i + 7),
    });
  }

  handleAgeChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    if (newvalue >= 20 && newvalue <= 85) {
      this.setState({ age: newvalue }, () => {
        this.buttonDisabled();
        this.updateLivebirthOptions();
        this.updateAgeMenOptions();
      });
      if (this.state.ageMen > this.state.age) {
        this.setState({ ageMen: newvalue }, () => {
          this.buttonDisabled();
          this.updateLivebirthOptions();
          this.updateAgeMenOptions();
        });
      }
    } else {
      this.setState({ age: this.state.age }, () => {
        this.buttonDisabled();
        this.updateLivebirthOptions();
        this.updateAgeMenOptions();
      });
    }
  };

  handleAgeMenChange = (event, value) => {
    let newvalue = -1;
    if (!isNaN(event.target.value)) {
      newvalue = parseInt(event.target.value);
    }
    if ((newvalue >= 7 && newvalue <= this.state.age) || newvalue === -1) {
      this.setState({ ageMen: newvalue }, () => {
        this.buttonDisabled();
        this.updateLivebirthOptions();
        this.updateAgeOptions();
      });
    } else if (newvalue !== -1) {
      this.setState({ ageMen: this.state.age }, () => {
        this.buttonDisabled();
        this.updateLivebirthOptions();
        this.updateAgeOptions();
      });
    }
  };

  handleLiveBirthChange = (event, value) => {
    const newvalue = parseInt(event.target.value);

    if (newvalue >= this.state.ageMen && newvalue <= this.state.age) {
      this.setState({ livebirth: newvalue }, () => {
        this.buttonDisabled();
        this.updateAgeMenOptions();
      });
    } else if (newvalue === 0) {
      this.setState({ livebirth: this.state.ageMen }, () => {
        this.buttonDisabled();
        this.updateAgeMenOptions();
      });
    } else {
      this.setState({ livebirth: newvalue }, () => {
        this.buttonDisabled();
        this.updateAgeMenOptions();
      });
    }
  };

  handleRelativesChange = (event, value) => {
    const newvalue = parseInt(event.target.value);
    if (newvalue >= -1 && newvalue <= 99) {
      this.setState({ relatives: newvalue }, () => {
        this.buttonDisabled();
      });
    } else {
      this.setState({ relatives: this.state.relatives }, () => {
        this.buttonDisabled();
      });
    }
  };

  handleBiopsyChange = (event, value) => {
    const newvalue = parseInt(event.target.value);

    if (newvalue >= -1 && newvalue <= 99) {
      this.setState({ biopsy: newvalue }, () => {
        this.buttonDisabled();
        if (this.state.biopsy === 0) {
          this.setState({ hyperplasia: -1 }, () => {
            this.buttonDisabled();
          });
        }
      });
    } else {
      this.setState({ biopsy: this.state.biopsy }, () => {
        this.buttonDisabled();
      });
    }
  };

  handleHyperplasiaChange = (event, value) => {
    this.setState({ hyperplasia: value }, () => {
      this.buttonDisabled();
    });
  };

  handleRaceChange = (event, value) => {
    this.setState({ race: value }, () => {
      this.buttonDisabled();
    });
  };

  buttonDisabled() {
    this.setState({
      buttonDisabled: !(
        this.state.age > 20 &&
        (this.state.livebirth < 0 ||
          this.state.livebirth >= this.state.ageMen) &&
        this.state.ageMen <= this.state.age &&
        (this.state.biopsy === 0 ||
          this.state.biopsy === -1 ||
          (this.state.biopsy > 0 && this.state.hyperplasia >= 0)) &&
        this.state.race !== 0
      ),
    });
  }

  updateLivebirthOptions() {
    const ageMen = this.state.ageMen !== -1 ? this.state.ageMen : 7;
    const size = parseInt(this.state.age) - ageMen + 1;

    this.setState({
      livebirthOptions: Array.from(Array(size), (e, i) => i + ageMen),
    });
  }

  updateAgeMenOptions() {
    const size =
      parseInt(this.state.livebirth) > 0
        ? parseInt(this.state.livebirth) - 6
        : parseInt(this.state.age) - 6;

    this.setState({
      ageMenOptions: Array.from(Array(size), (e, i) => i + 7),
    });
  }

  updateAgeOptions() {
    const size = 85 - parseInt(this.state.ageMen);

    this.setState({
      ageOptions: Array.from(Array(size), (e, i) => i + this.state.ageMen),
    });
  }

  sendMessage(message) {
    if (window.appInterface !== undefined) {
      // Call Android interface
      window.appInterface.postMessage(message);
    } else if (window.webkit && window.webkit.messageHandlers) {
      // Call iOS interface
      window.webkit.messageHandlers.testMessage.postMessage(message);
    } else {
      // No Android or iOS interface found
      console.log("No native APIs found.");
    }
  }

  createApolloClient(dep_id = "dev", queryBody) {
    const httpLink = createHttpLink({
      uri:
        dep_id === "dev"
          ? BcraConstants.apiDevUrl
          : dep_id === "qa"
          ? BcraConstants.apiQAUrl
          : BcraConstants.apiStagingUrl,
    });

    const authLink = setContext((_, { headers }) => {
      // get the authentication token from local storage if it exists
      let token = localStorage.getItem("token");
      // return the headers to the context so httpLink can read them
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      };
    });

    const client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });

    client
      .query({
        query: gql`
          ${queryBody}
        `,
      })
      .then((result) => {
        this.setState({
          lifetimeRisk: (
            (result.data.brCaRiskEstimation.lifetimeRisk.absoluteRisk * 10000) /
            100
          ).toFixed(2),
        });
        this.setState({
          projectedRisk: (
            (result.data.brCaRiskEstimation.projectedAgeRisk.absoluteRisk *
              10000) /
            100
          ).toFixed(2),
        });
        this.setState({
          lifetimePopRisk: (
            (result.data.brCaRiskEstimation.lifetimeRisk.averagePopulationRisk *
              10000) /
            100
          ).toFixed(2),
        });
        this.setState({
          projectedPopRisk: (
            (result.data.brCaRiskEstimation.projectedAgeRisk
              .averagePopulationRisk *
              10000) /
            100
          ).toFixed(2),
        });
        this.setState({ circularVisibility: "hidden" });
        this.setState({ resultVisibility: "block" });
        this.setState({ buttonDisabled: false });
        this.sendMessage(
          `pAge=${this.state.age},pRisk=${this.state.projectedRisk},pAvg=${this.state.projectedPopRisk},ltRisk=${this.state.lifetimeRisk},ltAvg=${this.state.lifetimePopRisk}`
        );
      })
      .catch((error) => {
        this.setState({ circularVisibility: "hidden" });

        //this.setState({ resultVisibility: "block" });
        this.setState({ buttonDisabled: false });

        console.log(error);
      });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    const hyperplasia =
      parseInt(this.state.hyperplasia) === -1
        ? 99
        : parseInt(this.state.hyperplasia);

    let ageMenText =
      this.state.ageMen === -1 ? "\n" : `menarchyAge: ${this.state.ageMen}`;

    let livebirthText =
      this.state.livebirth === -1
        ? `ageAtFirstBirth: 27`
        : this.state.livebirth === -2
        ? `ageAtFirstBirth: 19`
        : `ageAtFirstBirth: ${this.state.livebirth}`;

    let hyperplasiaText = "\n";
    if (this.state.biopsy > 0 && (hyperplasia === 0 || hyperplasia === 1)) {
      hyperplasiaText = `atypicalHyperplasia:${hyperplasia === 1}\n`;
    }

    const relativesText =
      this.state.relatives === -1
        ? "\n"
        : `nRelatives:${this.state.relatives}\n`;
    const biopsyText =
      this.state.biopsy === -1 ? "\n" : `nBiopsies:${this.state.biopsy}\n`;

    const queryBody = `query {
            brCaRiskEstimation(
              age: ${this.state.age}
              projectedAge: ${parseInt(this.state.age) + 5}
              ${ageMenText}
              ${livebirthText}
              race: "${this.state.race.toString()}"
              ${biopsyText}
              ${hyperplasiaText}
              ${relativesText}) {
              projectedAgeRisk {
                absoluteRisk
                averagePopulationRisk
              }
              lifetimeRisk {
                absoluteRisk
                averagePopulationRisk
              }
            }
          }`;

    this.setState({ buttonDisabled: true });
    this.setState({ circularVisibility: "display" });

    this.createApolloClient(this.state.dep_id, queryBody);
  };

  render() {
    const sp = new URLSearchParams(window.location.search);

    if (sp.has("dep_id") && sp.has("token")) {
      window.localStorage.setItem("token", sp.get("token"));
      window.localStorage.setItem("token-exp", Date.now + 360000000);
    } else if (!isAuthenticated()) {
      return <Navigate to="/login" />;
    }

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 8,
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Gail Model for Breast Cancer Risk
            </Typography>
            <Typography component="h3" variant="h3">
              Estimate your risk for breast cancer based on demographic and
              clinical data.
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={this.handleSubmit}
              sx={{ mt: 3 }}
            >
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 0, sm: 0, md: 0 }}
              >
                <Grid item xs={12} sm={12}>
                  <div className="orange-background">
                    IMPORTANT <br />
                    This calculator includes inputs based on race, which may or
                    may not provide better estimates; this calculator can be run
                    with Race as “Unknown,” but users should know this defaults
                    to the “White” option. See{" "}
                    <a href="https://www.mdcalc.com/race">here</a> for more on
                    our approach to addressing race and bias.
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className="green-background">
                    INSTRUCTIONS <br />
                    The Gail Model is for use in women with no history of breast
                    cancer, DCIS or LCIS. Other tools may be more appropriate
                    for women with known mutations in BRCA1, BRCA2, or other
                    hereditary syndromes associated with breast cancer. See the
                    Evidence section for more information.
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Age</div>
                  <div className="subbody-app">
                    Valid for women 20-85 years old.
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <Select
                    id="Men-select"
                    value={this.state.age}
                    label=""
                    size="small"
                    onChange={this.handleAgeChange}
                    selected={this.state.age}
                    sx={{ width: "200px" }}
                  >
                    {this.state.ageOptions.map((item) => {
                      return (
                        <MenuItem key={"key-" + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">First menstrual period</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <Select
                    id="demo-simple-select"
                    value={this.state.ageMen}
                    size="small"
                    label=""
                    onChange={this.handleAgeMenChange}
                    selected={this.state.ageMen}
                    sx={{ width: "200px" }}
                  >
                    <MenuItem value={-1} selected>
                      Unknown
                    </MenuItem>

                    {this.state.ageMenOptions.map((item) => {
                      return (
                        <MenuItem
                          key={"key-" + item}
                          value={isNaN(item) ? -1 : item}
                        >
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">First live birth</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.livebirth}
                    size="small"
                    label=""
                    onChange={this.handleLiveBirthChange}
                    sx={{ width: "200px" }}
                  >
                    <MenuItem value={-2} selected>
                      Unknown
                    </MenuItem>
                    <MenuItem value={-1} selected>
                      No birth
                    </MenuItem>

                    {this.state.livebirthOptions.map((item) => {
                      return (
                        <MenuItem key={"key-" + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">
                    First-degree relatives with breast cancer
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.relatives}
                    size="small"
                    label="Relatives"
                    onChange={this.handleRelativesChange}
                    sx={{ width: "200px" }}
                  >
                    <MenuItem value={-1}>Unknown</MenuItem>
                    <MenuItem value={0} selected>
                      0
                    </MenuItem>

                    {[1, 2, 3, 4, 5].map((item) => {
                      return (
                        <MenuItem key={"key-" + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Previous breast biopsy</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.biopsy}
                    size="small"
                    label="Biopsy"
                    onChange={this.handleBiopsyChange}
                    sx={{ width: "200px" }}
                  >
                    <MenuItem value={-1}>Unknown</MenuItem>
                    <MenuItem value={0} selected>
                      0
                    </MenuItem>

                    {[1, 2, 3, 4, 5].map((item) => {
                      return (
                        <MenuItem key={"key-" + item} value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                    visibility: this.state.biopsy > 0 ? "display" : "hidden",
                  }}
                >
                  <div className="body-app">
                    Did biopsy display atypical hyperplasia?
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                    visibility: this.state.biopsy > 0 ? "display" : "hidden",
                  }}
                >
                  <ToggleButtonGroup
                    value={this.state.hyperplasia}
                    exclusive
                    onChange={this.handleHyperplasiaChange}
                  >
                    <ToggleButton value="0" size="small">
                      No
                    </ToggleButton>
                    <ToggleButton value="1" size="small">
                      Yes
                    </ToggleButton>
                    <ToggleButton value="99" size="small">
                      Unknown, N/A
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <div className="body-app">Race/ethnicity</div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "15px",
                  }}
                >
                  <ToggleButtonGroup
                    orientation="vertical"
                    value={this.state.race}
                    exclusive
                    onChange={this.handleRaceChange}
                  >
                    <ToggleButton value="1" size="small">
                      White
                    </ToggleButton>
                    <ToggleButton value="2" size="small">
                      African-American
                    </ToggleButton>
                    <ToggleButton value="3" size="small">
                      Hispanic-American (US born) 1995-04
                    </ToggleButton>
                    <ToggleButton value="4" size="small">
                      Other (Native American and unknown race)
                    </ToggleButton>
                    <ToggleButton value="5" size="small">
                      Hispanic-American (Foreign born) 1995-04
                    </ToggleButton>
                    <ToggleButton value="6" size="small">
                      Chinese
                    </ToggleButton>
                    <ToggleButton value="7" size="small">
                      Japanese
                    </ToggleButton>
                    <ToggleButton value="8" size="small">
                      Filipino
                    </ToggleButton>
                    <ToggleButton value="9" size="small">
                      Hawaiian
                    </ToggleButton>
                    <ToggleButton value="10" size="small">
                      Other Pacific Islander
                    </ToggleButton>
                    <ToggleButton value="11" size="small">
                      Other Asian
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Grid>
              </Grid>
              <Button
                type="submit"
                disabled={this.state.buttonDisabled}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#0b988a" }}
              >
                Submit
              </Button>

              <CircularProgress
                color="inherit"
                sx={{ visibility: this.state.circularVisibility }}
              />
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 0, sm: 0, md: 0 }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "5px",
                    color: "#fff",
                    backgroundColor: "#0b988a",
                    visibility: this.state.resultVisibility,
                  }}
                >
                  <div className="score-main">
                    Projected risk at {parseInt(this.state.age) + 5} years
                  </div>
                  <div className="score">{this.state.projectedRisk}%</div>
                  <div className="score-sub">
                    The average risk in the population is{" "}
                    {this.state.projectedPopRisk}%
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    borderTop: 1,
                    borderColor: "#d3d3d3",
                    marginTop: "5px",
                    color: "#fff",
                    backgroundColor: "#0b988a",
                    visibility: this.state.resultVisibility,
                  }}
                >
                  <div className="score-main">Lifetime risk</div>
                  <div className="score">{this.state.lifetimeRisk}%</div>
                  <div className="score-sub">
                    The average risk in the population is{" "}
                    {this.state.lifetimePopRisk}%
                  </div>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Copyright sx={{ mt: 5 }} />
        </Container>
      </ThemeProvider>
    );
  }
}

export default GailForm;
