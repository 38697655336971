export const BcraConstants = {
  loginUrl: "https://gcw-devtmp.genomsys.com/o/token/",
  loginQAUrl: "https://gcw-devtmp.genomsys.com/o/token/",
  apiDevUrl: "https://gcw-devtmp.genomsys.com/graphql/",
  apiQAUrl: "https://gcw-qa.genomsys.com/graphql/",
  apiStagingUrl: "https://gcw-staging.genomsys.com/graphql/",
  testUrl: "https://httpbin.org/post",
  appId:
    "1n4ntY7lQNX0PJqTZX1vocoEkceOIHgEo64Rgel2:9S2VrZ63tPohMOpvv6ZsqpjuuuHqLbGWpJZXU7a8D8q6wvmt1WNHtEHoskiplkp61hPzbbTKyQWas4Lb5baOWPgIBNXV9G9UMp70PXn7iKnXTtyUnwB5JtMICnFVyJXU",
  clientId: "1n4ntY7lQNX0PJqTZX1vocoEkceOIHgEo64Rgel2",
  clientSecret:
    "9S2VrZ63tPohMOpvv6ZsqpjuuuHqLbGWpJZXU7a8D8q6wvmt1WNHtEHoskiplkp61hPzbbTKyQWas4Lb5baOWPgIBNXV9G9UMp70PXn7iKnXTtyUnwB5JtMICnFVyJXU",
};

export const DiabetesConstants = {
  riskMessage:
    "High risk. Scores ≥5 should be formally screened for diabetes per ADA guidelines.",
  noRiskMessage:
    "Not high risk. T2DM screening not recommended by ADA guidelines.",
};
