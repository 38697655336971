import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { BcraConstants } from "./constants";
import { isAuthenticated } from "./utils";

import { createTheme, ThemeProvider } from "@mui/material/styles";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://genomsys.com/">
        GenomSys SA
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const theme = createTheme();

export default function Login() {
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };

    const params = new URLSearchParams({
      grant_type: "password",
      username: data.get("email"),
      password: data.get("password"),
      client_id: BcraConstants.clientId,
      client_secret: BcraConstants.clientSecret,
    });

    axios.post(loginUrl, params.toString(), config).then(
      (response) => {
        if (response) {
          window.localStorage.setItem("token", response.data.access_token);
          window.localStorage.setItem(
            "token-exp",
            Date.now() + response.data.expires_in
          );

          window.location.href = "/gail-form";
        }
      },
      (error) => {
        console.log(error);
        window.location.href = "/login";
      }
    );
  };

  const sp = new URLSearchParams(window.location.search);
  let loginUrl = BcraConstants.loginUrl;

  if (isAuthenticated()) {
    // Redirect to home page
    window.location.href = "/gail-form";
  } else if (sp.has("dep_id") && sp.has("token")) {
    window.localStorage.setItem("token", sp.get("token"));
    window.localStorage.setItem("token-exp", Date.now + 360000000);
    window.location.href = "/gail-form?dep_id=" + sp.get("dep_id");
  } else if (sp.has("dep_id")) {
    loginUrl = `https://gcw-${sp.get("dep_id")}.genomsys.com/o/token/`;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  );
}
